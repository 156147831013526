import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

import PageTitle from "./PageTitle";

const StyledLi = styled.li`
  display: inline;
  &:first-child {
    &::before {
      content: none;
      padding-left: 0;
    }
  }
  &::before {
    content: "〉";
    padding: 0.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    color: ${colors.secondary};
  }
`;
const BreadcrumbLink = styled(Link)`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.secondary};
  text-decoration: none;
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.2rem;
  }
  &:hover {
    color: ${colors.lightGray};
    text-decoration: underline;
  }
`;
const BreadcrumbItemContainer = styled.ol`
  list-style: none;
  font-size: 1rem;
  max-width: ${breakpoints.xl};
  margin: 0 auto;
  padding: 0 0 0 1rem;
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.25rem;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 0 0 0.2rem 0;
  }
`;
const BreadcrumbContainer = styled.div`
  background-color: ${colors.primaryDark};
  width: 100%;
  padding: 1.5rem 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 2rem 0;
  }
`;
export const Breadcrumb = ({ children, pageTitle }) => (
  <BreadcrumbContainer>
    <BreadcrumbItemContainer>
      <BreadcrumbItem Link="/" Text="Home" />
      {children}
    </BreadcrumbItemContainer>
    <PageTitle>{pageTitle}</PageTitle>
  </BreadcrumbContainer>
);

export const BreadcrumbItem = ({
  Link,
  Text,
}: {
  Link: string;
  Text: string;
}) => (
  <StyledLi>
    <BreadcrumbLink to={Link}>{Text}</BreadcrumbLink>
  </StyledLi>
);
