/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type Props = {
  description?: string;
  lang?: string;
  meta?: Array<any>;
  title: string;
  image?: {
    metaImage: {
      src: string;
      width: number;
      height: number;
    };
  };
};

const SEO: FunctionComponent<Props> = ({
  description = "Gippsland Orthodontics, dentistry. providing dental services - dental implants, bridge, crowns, onlays, Inlays, Venners, Laser Dentistry, TMD and dentures",
  lang = "en",
  meta = [],
  title,
  image,
}) => {
  const { site, siteBanner } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        siteBanner: file(relativePath: { eq: "GOlogoWebPage.jpeg" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              width
              height
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaImageFullPath = image?.metaImage.src
    ? `${site.siteMetadata.siteUrl}${image.metaImage.src}`
    : `${site.siteMetadata.siteUrl}${siteBanner.childImageSharp.resize.src}`;
  const metaImageWidth =
    image?.metaImage.width ?? siteBanner.childImageSharp.resize.width;
  const metaImageHeight =
    image?.metaImage.height ?? siteBanner.childImageSharp.resize.height;
  const socialTitle = `${title} | ${site.siteMetadata.title}`;

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "MedicalWebPage",
      "@id": site.siteMetadata.siteUrl,
      url: site.siteMetadata.siteUrl,
      name: site.siteMetadata.title,
    },
  ];
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: socialTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: socialTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImageFullPath
            ? [
                {
                  property: "og:image",
                  content: metaImageFullPath,
                },
                {
                  property: "og:image:width",
                  content: metaImageWidth,
                },
                {
                  property: "og:image:height",
                  content: metaImageHeight,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(meta)}
    >
      <script type="application/Id+jason">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  );
};

export default SEO;
