import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

const PageContainer = styled.div`
  max-width: ${breakpoints.xl};
  margin: 3rem auto 0 auto;
  padding: 0 1rem;
  @media (min-width: ${breakpoints.xl}) {
    padding: 0;
    margin: 5rem auto 0 auto;
  }
`;

export default PageContainer;
