import React from "react";
import AboutImage from "./Images";
import {
  Container,
  ImageContainer,
  ContentsContainer,
  Title,
  Text,
  Wrapper,
  BannerImageMD,
  BannerImageSM,
  DotIcon,
  ListBox,
} from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";

const Orthodontist = () => {
  return (
    <>
      <Breadcrumb pageTitle="Who We Are">
        <BreadcrumbItem Link="/orthodontist" Text="Who We Are" />
      </Breadcrumb>

      <PageContainer>
        <Container>
          <Title>Who is our local orthodontist?</Title>
          <Wrapper>
            <ContentsContainer>
              <Text>
                Adam Wallace is a specialist orthodontist who has had more than
                17 years of experience in the dental profession. He was born and
                raised in Arawata, South Gippsland. His mum owned the Quilter’s
                Barn in Loch and his dad worked with Graham Rowe at Vin Rowe
                Farm Machinery of which he has had many fond memories as a kid!
              </Text>
              <Text>
                Adam attended Chairo Christian School (Leongatha campus) and
                went on to complete both his Dentistry and Orthodontic
                specialist training at the University of Melbourne. He has
                continued to lecture, demonstrate and examine at the University
                since graduating in 2005. Additionally, he examines overseas
                trained dentists at the Australian Dental Council. As much as
                Adam loves teaching, the majority of his career has been spent
                at a practice in Heathmont, Melbourne.
              </Text>
              <Text>
                Adam’s undergraduate research was quite apt coming from a dairy
                farm. He studied the casein proteins in milk and the protective
                effect on teeth to help reduce plaque formation. Milk is
                important for teeth and funnily enough, it seems to be an
                integral part of the family with his cousin’s husband being one
                half of the team running the awesome local business, Gippsland
                Jersey! Adam went on to graduate the Bachelor of Dental Science
                degree as valedictorian with honours and received a total of ten
                scholarships, prizes, awards and special achievements throughout
                his undergraduate degree.
              </Text>
              <Text>
                Following graduation, he went onto his Masters, researching the
                effects of bisphosphonate use on treatment of benign bone
                disease and impaired dental healing. His passion for academics
                pursued and he has held several academic roles including the
                Clinical Practice and Specialist Areas in Dentistry
                (Orthodontics) Convenor for the Bachelor of Oral Health program.
                Recently, his doctoral thesis was an important clinical study on
                the treatment outcome between Invisalign and traditional braces
                which intends to be published soon.
              </Text>
              <Text>
                Adam is passionate about orthodontics and thoroughly enjoys
                creating beautiful smiles for his patients. He likes to provide
                a personalised approach to his treatment to ensure that patients
                feel welcome and comfortable. Further to this, specialist
                orthodontic training and certification with the Australasian
                Board of Orthodontists has allowed him to appreciate the finer
                attention to detail in treatment.
              </Text>
              <Text>
                Outside Adam’s passion for his work and devotion to his
                patients, he is kept busy with a farm in the Strzelecki ranges
                where he has been living for the last 10 years. He currently has
                angus cattle, sheep, alpacas and two border collie x kelpie
                dogs. Family is also really important, and he looks forward to
                being able to spend more time with his family in the local areas
                of Drouin, Jindivick, Inverloch and Sale.
              </Text>
              <Text>
                Adam aims to provide specialist care for all his patients with a
                kind, gentle nature and looks forward to seeing several
                generations of patients in the future.
              </Text>
              <Text>
                Dr Adam provides treatment for all ages with affordable
                interest-free payment plans at Gippsland Orthodontics.
              </Text>
              <ListBox>
                <DotIcon />
                <p>Traditional braces</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Clear braces</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Invisalign and clear aligner treatment</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Early intervention orthodontic treatment</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Teen and adult treatment</p>
              </ListBox>
            </ContentsContainer>
            <ImageContainer>
              <AboutImage />
            </ImageContainer>
          </Wrapper>
        </Container>
      </PageContainer>
    </>
  );
};

export default Orthodontist;
