import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Orthodontist from "../components/orthodontist";
import { colors } from "../styles/variables";

const AboutUsPage = () => (
  <Layout bgColor={colors.light}>
    <SEO title="About Our Principal Orthodontist" />
    <Orthodontist />
  </Layout>
);

export default AboutUsPage;
